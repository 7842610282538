<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9993 2.66602H3.99935C3.26297 2.66602 2.66602 3.26297 2.66602 3.99935V5.33268C2.66602 6.06906 3.26297 6.66602 3.99935 6.66602H11.9993C12.7357 6.66602 13.3327 6.06906 13.3327 5.33268V3.99935C13.3327 3.26297 12.7357 2.66602 11.9993 2.66602Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9993 9.33203H3.99935C3.26297 9.33203 2.66602 9.92898 2.66602 10.6654V11.9987C2.66602 12.7351 3.26297 13.332 3.99935 13.332H11.9993C12.7357 13.332 13.3327 12.7351 13.3327 11.9987V10.6654C13.3327 9.92898 12.7357 9.33203 11.9993 9.33203Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
