import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';
import axios from 'axios';
import storageKey from '@/constants/storageKey';
import localStorage from './utils/localStorage';
import vMoney from '@/directives/vMoney';
import jwtDecode from 'jwt-decode';

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

const authToken = localStorage.get(storageKey.AUTH_TOKEN);
if (authToken) {
  axios.defaults.headers.common['Authorization'] = authToken;
}

let isExpired = false;
axios.interceptors.request.use(
  (req: any) => {
    if (req.headers.common.Authorization && !isExpired) {
      const tokenParsed: any = jwtDecode(
        req.headers.common.Authorization.replace(/Bearer /i, '')
      );

      const expirationTime = +new Date(tokenParsed.exp * 1000);
      const now = +new Date();
      const minimumGapForRefresh = 1000 * 5; // 5초 후에 토큰이 만료된다면 초기화한다.

      if (now + minimumGapForRefresh > expirationTime) {
        isExpired = true;
        // TODO: 토큰 재발급 API 호출. 토큰 재발급 대신 로그아웃시킴
        delete axios.defaults.headers.common['Authorization'];
        localStorage.remove(storageKey.AUTH_TOKEN);
        alert('인증 만료되었습니다. 다시 로그인해 주세요.');
        window.location.href = '/';
      } else {
        isExpired = false;
      }
    }
    return req;
  },
  (err) => err
);

const renderApp = () => {
  const app = createApp(App);
  app.directive('money', vMoney);
  app.use(store).use(router).mount('#app');
};

if (
  process.env.NODE_ENV === 'development' &&
  process.env.VUE_APP_MSW_WORKER_ENABLED === 'true'
) {
  // run mock API server with MSW
  import('./__mocks__/mockWorker').then(({ worker }) => {
    worker.start().then(() => {
      renderApp();
    });
  });
} else {
  renderApp();
}
