<template>
  <svg
    width="149"
    height="22"
    viewBox="0 0 149 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20.833V1.23145H2.94076C4.25058 1.23145 5.29422 1.33301 6.07166 1.53613C6.85755 1.73926 7.52092 2.08203 8.06174 2.56445C8.61102 3.0638 9.04622 3.68587 9.36734 4.43066C9.69691 5.18392 9.86169 5.94564 9.86169 6.71582C9.86169 8.12077 9.32509 9.3099 8.25188 10.2832C9.29129 10.6387 10.111 11.2607 10.711 12.1494C11.3194 13.0296 11.6236 14.0537 11.6236 15.2217C11.6236 16.7536 11.0828 18.0485 10.0011 19.1064C9.35044 19.7581 8.61948 20.2109 7.80823 20.4648C6.92093 20.7103 5.8097 20.833 4.47452 20.833H0ZM2.95344 9.64844H3.87876C4.97732 9.64844 5.78012 9.40723 6.28715 8.9248C6.80263 8.43392 7.06036 7.71452 7.06036 6.7666C7.06036 5.84408 6.7984 5.14583 6.27447 4.67188C5.75054 4.18945 4.99 3.94824 3.99285 3.94824H2.95344V9.64844ZM2.95344 18.0527H4.77874C6.11391 18.0527 7.09417 17.7904 7.7195 17.2656C8.37864 16.6986 8.70821 15.9792 8.70821 15.1074C8.70821 14.2611 8.39131 13.5501 7.75753 12.9746C7.14064 12.4076 6.03786 12.124 4.44917 12.124H2.95344V18.0527Z"
      fill="white"
    />
    <path
      d="M20.9124 1.23145V18.0527H26.6671V20.833H17.9589V1.23145H20.9124Z"
      fill="white"
    />
    <path
      d="M44.6514 16.0723H36.2728L34.0926 20.833H30.911L40.5952 0L49.9372 20.833H46.7049L44.6514 16.0723ZM43.4472 13.292L40.5445 6.62695L37.5023 13.292H43.4472Z"
      fill="white"
    />
    <path
      d="M67.5426 2.28516V5.78906C65.8356 4.35872 64.0694 3.64355 62.2441 3.64355C60.2329 3.64355 58.5386 4.36719 57.1612 5.81445C55.7753 7.25326 55.0824 9.01367 55.0824 11.0957C55.0824 13.1523 55.7753 14.8874 57.1612 16.3008C58.5471 17.7142 60.2456 18.4209 62.2568 18.4209C63.2962 18.4209 64.1793 18.2516 64.906 17.9131C65.3117 17.7438 65.7299 17.5153 66.1609 17.2275C66.6003 16.9398 67.0609 16.5928 67.5426 16.1865V19.7539C65.8525 20.7103 64.0779 21.1885 62.2188 21.1885C59.4217 21.1885 57.0344 20.2109 55.057 18.2559C53.0881 16.2839 52.1036 13.9056 52.1036 11.1211C52.1036 8.62435 52.9275 6.39844 54.5753 4.44336C56.6034 2.04818 59.2273 0.850586 62.4469 0.850586C64.2046 0.850586 65.9032 1.32878 67.5426 2.28516Z"
      fill="white"
    />
    <path
      d="M77.3891 9.05176L85.0072 1.23145H88.9493L80.1017 10.1055L89 20.833H85.0452L77.9975 12.1113L77.3891 12.7207V20.833H74.4356V1.23145H77.3891V9.05176Z"
      fill="white"
    />
    <path
      d="M144.296 5.54549V20.8335H139.2V5.54549H135.014V1.22949H148.482V5.54549H144.296Z"
      fill="white"
    />
    <path
      d="M103.194 1.22949V16.5175H109.304V20.8335H98.098V1.22949H103.194Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M117.651 11C117.651 13.7614 119.89 16 122.651 16C125.413 16 127.651 13.7614 127.651 11C127.651 8.23858 125.413 6 122.651 6C119.89 6 117.651 8.23858 117.651 11ZM122.651 1C117.129 1 112.651 5.47715 112.651 11C112.651 16.5228 117.129 21 122.651 21C128.174 21 132.651 16.5228 132.651 11C132.651 5.47715 128.174 1 122.651 1Z"
      fill="white"
    />
  </svg>
</template>
