<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00065 2.33398H2.66732C2.3137 2.33398 1.97456 2.47446 1.72451 2.72451C1.47446 2.97456 1.33398 3.3137 1.33398 3.66732V11.6673C1.33398 12.0209 1.47446 12.3601 1.72451 12.6101C1.97456 12.8602 2.3137 13.0007 2.66732 13.0007H9.33398C9.68761 13.0007 10.0267 12.8602 10.2768 12.6101C10.5268 12.3601 10.6673 12.0209 10.6673 11.6673V3.66732C10.6673 3.3137 10.5268 2.97456 10.2768 2.72451C10.0267 2.47446 9.68761 2.33398 9.33398 2.33398H8.00065"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66667 1H5.33333C4.59695 1 4 1.59695 4 2.33333C4 3.06971 4.59695 3.66667 5.33333 3.66667H6.66667C7.40305 3.66667 8 3.06971 8 2.33333C8 1.59695 7.40305 1 6.66667 1Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 7H4.00667"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66797 7H8.0013"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 9.66602H4.00667"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66797 9.66602H8.0013"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
