<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AuthLayout',
  props: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
