<template>
  <div :class="['Sidebar', {'wide': isWide}]">
<!--    <mark v-if="mode === 'development'" class="absolute">개발모드</mark>-->
    <div class="Sidebar__Logo">
      <transition name="fade">
        <div v-if="isWide">
          <Logo></Logo>
          <MenuCloseIcon @click="menuWidthHandler" class="menu-close" />
        </div>
      </transition>
      <transition name="fade">
        <MenuIcon
            v-if="!isWide"
            @click="menuWidthHandler"
        />
      </transition>
    </div>
    <div class="Sidebar__subTitle">Partner Center</div>
    <div class="Sidebar__menulist">
      <router-link to="/app/dashboard" class="Sidebar__menuItem">
        <DanshboardIcon></DanshboardIcon>
        <span>대시보드</span>
      </router-link>
      <router-link to="/app/sales" class="Sidebar__menuItem">
        <SalesIcon></SalesIcon>
        <span>판매현황</span>
      </router-link>
      <router-link to="/app/makeOffer" class="Sidebar__menuItem">
        <MakeOfferIcon></MakeOfferIcon>
        <span>Make Offer</span>
      </router-link>
      <router-link to="/app/product" class="Sidebar__menuItem">
        <ProductIcon></ProductIcon> <span>상품관리</span>
      </router-link>
      <router-link to="/app/settlement" class="Sidebar__menuItem">
        <SettlementIcon></SettlementIcon>
        <span>정산관리</span>
      </router-link>
      <router-link to="/app/qna" class="Sidebar__menuItem">
        <QnaIcon></QnaIcon>
        <span>1:1 문의관리</span>
      </router-link>
      <router-link to="/app/member" class="Sidebar__menuItem">
        <SettingsIcon></SettingsIcon>
        <span>파트너정보 관리</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import Logo from './Logo.vue';
import DanshboardIcon from '@/views/layouts/AppLayout/icons/DanshboardIcon.vue';
import SalesIcon from '@/views/layouts/AppLayout/icons/SalesIcon.vue';
import ProductIcon from '@/views/layouts/AppLayout/icons/ProductIcon.vue';
import SettlementIcon from '@/views/layouts/AppLayout/icons/SettlementIcon.vue';
import QnaIcon from '@/views/layouts/AppLayout/icons/QnaIcon.vue';
import MenuIcon from '@/views/layouts/AppLayout/icons/MenuIcon.vue';
import MenuCloseIcon from '@/views/layouts/AppLayout/icons/MenuCloseIcon.vue';
import MakeOfferIcon from '@/views/layouts/AppLayout/icons/MakeOfferIcon.vue'
import SettingsIcon from '@/views/layouts/AppLayout/icons/SettingsIcon.vue'

export default {
  components: {
    Logo,
    DanshboardIcon,
    SalesIcon,
    ProductIcon,
    SettlementIcon,
    QnaIcon,
    MenuIcon,
    MenuCloseIcon,
    MakeOfferIcon,
    SettingsIcon
  },
  setup() {
    const mode = computed(() => {
      return process.env.VUE_APP_ENV;
    });
    const isWide = ref(false)
    const menuWidthHandler = () => {
      isWide.value =! isWide.value
    }

    return {
      mode,
      isWide,
      menuWidthHandler
    };
  },
};
</script>

<style scoped lang="scss">
.Sidebar {
  &.wide {
    width: 248px;
    .Sidebar__Logo {
      justify-content: space-between;
    }
    .Sidebar__subTitle {
      opacity: 1;
      transition-delay: .1s;
    }
    .Sidebar__menuItem {
      > span {
        opacity: 1;
        width: auto;
        transition: opacity .1s .1s;
        font-size: inherit;
      }
    }
  }
  width: 48px;
  min-height: 100vh;
  color: #bbbbbb;
  transition: width .1s ease-out;
  svg {
    display: inline-block;
    stroke: #bbb;
    flex: none;
  }
}
.menu-close {
  //float: right;
}
.Sidebar__Logo {
  height: 72px;
  padding-left: 14px;
  padding-right: 14px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.Sidebar__subTitle {
  opacity: 0;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #49cdda;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-left: 24px;
  width: 248px;
  transition: opacity .5s;
}

.Sidebar__menulist {
}

.Sidebar__menuItem {
  display: block;
  align-items: center;
  align-items: center;
  height: 48px;
  padding: 10px 15px;
  justify-content: center;

  &:hover,
  &.router-link-active {
    background-color: #666;
    color: #ffffff;
    font-weight: 600;
    svg {
      stroke: #ffffff;
    }
  }

  & > span {
    width: 0;
    opacity: 0;
    margin-left: 10px;
    vertical-align: middle;
    font-size: 0;
  }
}
</style>
