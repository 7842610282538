<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9993 9.33333L11.9993 7.33333H7.33268C7.15587 7.33333 6.9863 7.26309 6.86128 7.13807C6.73625 7.01305 6.66602 6.84348 6.66602 6.66667V2.66667C6.66602 2.48986 6.73625 2.32029 6.86128 2.19526C6.9863 2.07024 7.15587 2 7.33268 2H13.3327C13.5095 2 13.6791 2.07024 13.8041 2.19526C13.9291 2.32029 13.9993 2.48986 13.9993 2.66667V9.33333Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33333 9.99935V11.3327C9.33333 11.5095 9.26309 11.6791 9.13807 11.8041C9.01305 11.9291 8.84348 11.9993 8.66667 11.9993H4L2 13.9993V7.33268C2 7.15587 2.07024 6.9863 2.19526 6.86128C2.32029 6.73625 2.48986 6.66602 2.66667 6.66602H4"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
