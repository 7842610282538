import convertNumWithComma from '@/utils/convertNumWithComma';

export default {
  mounted(el, bindings) {
    el.textContent = convertNumWithComma(bindings.value, el.dataset.unit);
  },
  updated(el, bindings) {
    el.textContent = convertNumWithComma(bindings.value, el.dataset.unit);
  },
};
