<template>
  <div class="AppLayout">
    <div class="AppLayout__sidebar">
      <Sidebar></Sidebar>
    </div>
    <div class="AppLayout__main">
      <Topbar></Topbar>
      <div class="AppLayout_routeView">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Topbar from './Topbar.vue';
export default {
  components: {
    Sidebar,
    Topbar,
  },
  data: () => {
    return {};
  },
};
</script>

<style scoped lang="scss">
.AppLayout {
  display: flex;
}

.AppLayout__sidebar {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background: #171717;
}

.AppLayout__main {
  position: relative;
  margin-left: 48px;
  flex: 1;
  background-color: #f5f6fb;
  &.bg-white {
    background: #fff;
  }
}

.AppLayout_routeView {
  min-width: 1672px;
  padding: 64px 36px;
}
</style>
