<template>
  <div class="Topbar shadow-md">
    <div class="Topbar__routename">
      {{ getRouteName($route) }}
    </div>
    <div class="Topbar__userInfoWrap">
      <!-- user grade and notification  -->

      <!-- user image -->
      <div
        class="ml-4 w-8 h-8 rounded-full bg-cover bg-center bg-gray-400"
        :style="{ backgroundImage: `url(${partnerInfo.profileImage})` }"
      ></div>

      <!-- usename -->
      <div
        class="ml-4 inline-flex items-center cursor-pointer"
        @click="handleClickName"
      >
        <span class="mr-4">{{ partnerInfo.name }}</span>
        <DropDownIcon></DropDownIcon>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import DropDownIcon from '@/components/icons/DropDownIcon.vue';
import storageKey from '@/constants/storageKey';
import localStorage from '@/utils/localStorage';
import axios from 'axios';

export default {
  components: {
    DropDownIcon,
  },
  data() {
    return {
      // TODO: API
      grade: 'Premium',
      notiCount: 20,
      userImage: 'https://via.placeholder.com/150/0000FF/808080?Text=User',
      userName: '갤러리어썸',
    };
  },
  methods: {
    getRouteName(route) {
      return route.name || route.matched.find((v) => !!v.name)?.name || 'Menu';
    },
  },
  setup() {
    const partnerInfo = computed(() => {
      return localStorage.get(storageKey.PARTNER_INFO);
    });
    const handleClickName = () => {
      // TODO: open dropdown menu
      alert('logout');
      localStorage.remove(storageKey.AUTH_TOKEN);
      localStorage.remove(storageKey.PARTNER_INFO);
      delete axios.defaults.headers.common['Authorization'];
      window.location.href = '/auth';
    };

    return {
      partnerInfo,
      handleClickName,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.Topbar {
  display: flex;
  align-items: center;
  padding: 0 36px;
  height: 72px;
  background: #fff;
}

.Topbar__routename {
  list-style-type: disc;
  font-weight: 700;
  padding-left: 30px;
  position: relative;
  font-size: 24px;

  /* dot */
  &::before {
    @include centerY;
    left: 0;
    display: block;
    background: #000;
    content: ' ';
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.Topbar__userInfoWrap {
  margin-left: auto;
  display: flex;
  align-items: center;
}
</style>
