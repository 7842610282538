<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99935 0.666016H1.33268C0.964492 0.666016 0.666016 0.964492 0.666016 1.33268V3.99935C0.666016 4.36754 0.964492 4.66602 1.33268 4.66602H3.99935C4.36754 4.66602 4.66602 4.36754 4.66602 3.99935V1.33268C4.66602 0.964492 4.36754 0.666016 3.99935 0.666016Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6654 0.666016H7.9987C7.63051 0.666016 7.33203 0.964492 7.33203 1.33268V3.99935C7.33203 4.36754 7.63051 4.66602 7.9987 4.66602H10.6654C11.0336 4.66602 11.332 4.36754 11.332 3.99935V1.33268C11.332 0.964492 11.0336 0.666016 10.6654 0.666016Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.99935 7.33203H1.33268C0.964492 7.33203 0.666016 7.63051 0.666016 7.9987V10.6654C0.666016 11.0336 0.964492 11.332 1.33268 11.332H3.99935C4.36754 11.332 4.66602 11.0336 4.66602 10.6654V7.9987C4.66602 7.63051 4.36754 7.33203 3.99935 7.33203Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6654 7.33203H7.9987C7.63051 7.33203 7.33203 7.63051 7.33203 7.9987V10.6654C7.33203 11.0336 7.63051 11.332 7.9987 11.332H10.6654C11.0336 11.332 11.332 11.0336 11.332 10.6654V7.9987C11.332 7.63051 11.0336 7.33203 10.6654 7.33203Z"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

