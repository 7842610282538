const convertNumWithComma = (value, unit = '원', placeholder = '-') => {
  if (value === 0) {
    return '0' + unit;
  }
  const withComma = value
    ? value
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit
    : placeholder;

  return withComma;
};

export const removeComma = (value = '') => {
  return value.toString().replace(/\D/g, '');
};

export default convertNumWithComma;
