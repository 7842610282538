import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AppLayout from '@/views/layouts/AppLayout/index.vue';
import AuthLayout from '@/views/layouts/AuthLayout/index.vue';
import RouterView from '@/views/RouterView.vue';
import localStorage from '@/utils/localStorage';
import storageKey from '@/constants/storageKey';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/auth',
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '',
        redirect: '/auth/signin',
      },
      {
        path: 'signin',
        component: () => import('@/views/SignIn/index.vue'),
      },
      {
        path: 'signup',
        component: RouterView,
        children: [
          {
            path: ':step',
            component: () => import('@/views/SignUp/index.vue'),
          },
          {
            path: '',
            redirect: '/auth/signup/1',
          },
        ],
      },
    ],
  },
  {
    path: '/etc',
    component: AuthLayout,
    children: [
      {
        path: '/etc/settlement/specification',
        name: '정산내역서',
        component: () => import('@/views/settlement/Specification.vue'),
      },
    ],
  },
  {
    path: '/app',
    component: AppLayout,
    children: [
      {
        path: '',
        redirect: '/app/dashboard',
      },
      {
        path: 'dashboard',
        name: '대시보드',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'sales',
        name: '판매현황',
        component: RouterView,
        children: [
          {
            path: 'list',
            component: () => import('@/views/sales/SalesList/index.vue'),
          },
          {
            path: 'detail/:productId',
            component: () => import('@/views/sales/SalesDetail/index.vue'),
          },
          {
            path: '',
            redirect: '/app/sales/list',
          },
        ],
      },
      {
        path: 'makeOffer',
        name: 'MakeOffer',
        component: RouterView,
        children: [
          {
            path: 'list',
            component: () => import('@/views/makeOffer/index.vue'),
          },
          {
            path: '',
            redirect: '/app/makeOffer/list',
          },
        ],
      },
      {
        path: 'product',
        name: '상품관리',
        component: RouterView,
        children: [
          {
            path: 'list',
            component: () => import('@/views/product/ProductList/index.vue'),
          },
          {
            path: 'bulkRegister',
            component: () =>
              import('@/views/product/ProductRegister/bulkRegister.vue'),
          },
          {
            path: 'register',
            component: () =>
              import('@/views/product/ProductRegister/index.vue'),
          },
          {
            path: '',
            redirect: '/app/product/list',
          },
        ],
      },
      {
        path: 'settlement',
        name: '정산관리',
        component: RouterView,
        children: [
          {
            path: '',
            redirect: '/app/settlement/list',
          },
          {
            path: 'list',
            component: () =>
              import('@/views/settlement/SettlementList/index.vue'),
          },
          {
            path: 'detail/:productId',
            component: () =>
              import('@/views/settlement/SettlementDetail/index.vue'),
          },
        ],
      },
      {
        path: 'member',
        name: '파트너정보 관리',
        component: RouterView,
        children: [
          {
            path: 'detail',
            component: () => import('@/views/Member/MemberDetail/index.vue'),
          },
          {
            path: 'edit',
            component: () => import('@/views/Member/MemberEdit/index.vue'),
          },
          {
            path: '',
            redirect: '/app/member/detail',
          },
        ],
      },
      {
        path: 'qna',
        name: '1:1 문의관리',
        component: () => import('@/views/QnA.vue'),
      },
      {
        path: 'settings',
        name: '정보확인 | 수정',
        component: () => import('@/views/Settings.vue'),
      },
      {
        path: 'guide',
        name: 'GUIDE',
        component: () => import('@/views/Guide/index.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// app route guard
router.beforeEach(async (to, from) => {
  const isAppRoute = to.path.startsWith('/app');
  const isAuthRoute = to.path.startsWith('/auth');
  const authToken = localStorage.get(storageKey.AUTH_TOKEN);

  if (isAppRoute) {
    if (!authToken) {
      return '/auth';
    } else {
      return true;
    }
  }

  if (isAuthRoute) {
    if (authToken) {
      return '/app';
    } else {
      return true;
    }
  }

  return true;
});

export default router;
