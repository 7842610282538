
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AuthLayout',
  props: {},
  setup() {
    return {};
  },
});
