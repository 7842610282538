<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1415 4C12.8718 4 12.6178 4.12504 12.456 4.3375L8 9.1875L3.54401 4.3375C3.38218 4.12504 3.1282 4 2.85848 4C2.15233 4 1.74926 4.79377 2.17294 5.35L8 12L13.8271 5.35C14.2507 4.79377 13.8477 4 13.1415 4Z"
      fill="#171717"
    />
  </svg>
</template>
